import createLabelStyles from '../../components/label/style-loader';
import createButtonStyles from '../../components/button/style-loader';
import createDateLabelStyles from './landing/components/date-input/style-loader';

export default function createStyles(config) {
  const {
    background_color: backgroundColor,
    min_width: minWidth,
    max_width: maxWidth,
    landing,
    error,
  } = config || {};
  const {
    background_image: landingImage,
    date: landingDate,
    button: landingButton,
    footer: landingFooter,
  } = landing;
  const { background_image: errorImage, label: errorLabel, footer: errorFooter } = error;
  const styles = [];

  createDateLabelStyles(landingDate, `.age-landing-date`).forEach((style) => {
    styles.push(style);
  });

  createButtonStyles(landingButton, `.age-landing-button`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(landingFooter, `.age-landing-footer`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(errorLabel, `.age-error-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(errorFooter, `.age-error-footer`).forEach((style) => {
    styles.push(style);
  });

  if (backgroundColor?.length > 0) {
    styles.push(`.age-gage-content.age-gage-content {background-color: ${backgroundColor};}`);
  }

  if (minWidth?.length > 0) {
    styles.push(
      `.age-landing-inner-container, .age-error-inner-container {min-width: ${minWidth};}`
    );
  }

  if (maxWidth?.length > 0) {
    styles.push(
      `.age-landing-inner-container, .age-error-inner-container {max-width: ${maxWidth};}`
    );
  }

  if (landingImage) {
    if (landingImage?.src?.length > 0) {
      styles.push(`.age-landing-background-image { content: url(${landingImage?.src}); }`);
    }
    let rules = '';
    if (landingImage?.min_width?.length > 0) {
      rules += `min-width: ${landingImage?.min_width}; `;
    }
    if (landingImage?.max_width?.length > 0) {
      rules += `max-width: ${landingImage?.max_width};`;
    }
    if (landingImage?.height?.length > 0) {
      rules += `height ${landingImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.age-landing-background-image-container { ${rules} }`);
    }
  }
  if (errorImage) {
    if (errorImage?.src?.length > 0) {
      styles.push(`.age-error-background-image { content: url(${errorImage?.src}); }`);
    }
    let rules = '';
    if (errorImage?.min_width?.length > 0) {
      rules += `min-width: ${errorImage?.min_width}; `;
    }
    if (errorImage?.max_width?.length > 0) {
      rules += `max-width: ${errorImage?.max_width};`;
    }
    if (errorImage?.height?.length > 0) {
      rules += `height: ${errorImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.age-error-background-image-container { ${rules} }`);
    }
  }
  return styles;
}
