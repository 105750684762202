import buttonStyleLoader from '../button/style-loader';

export default function createStyles(config, className = '.mobileOnly.mobileOnly') {
  const {
    background_image: backgroundImage,
    background_color: backgroundColor,
    button,
  } = config || {};

  const styles = [];
  let rules = '';
  if (backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} {${rules}}`);
  }

  if (backgroundImage) {
    if (backgroundImage?.src?.length > 0) {
      styles.push(`${className} > div > img { content: url(${backgroundImage?.src}); }`);
    }
  }
  if (button) {
    buttonStyleLoader(button, `${className} > div > .button`).forEach((rule) => {
      styles.push(rule);
    });
    const { left, right, top, bottom } = button;
    rules = '';
    if (left?.length > 0) {
      rules += `left:${left};`;
    }
    if (right?.length > 0) {
      rules += `right:${right};`;
    }
    if (top?.length > 0) {
      rules += `top:${top};`;
    }
    if (bottom?.length > 0) {
      rules += `bottom:${bottom};`;
    }

    if (rules.length > 0) {
      styles.push(`${className} > div > .button {${rules}}`);
    }
  }

  return styles;
}
