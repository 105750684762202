import createLabelStyles from '../../components/label/style-loader';
import createButtonStyles from '../../components/button/style-loader';

export default function createStyles(config) {
  const {
    background_color: backgroundColor,
    min_width: minWidth,
    max_width: maxWidth,
    landing,
    success,
    resubscribe,
  } = config || {};

  const {
    message_header: unsubscribeHeader,
    message_list: unsubscribeList,
    message_body: unsubscribeBody,
    message_footer: unsubscribeFooter,
    background_image: unsubscribeImage,
    button: unsubscribeButton,
  } = landing;

  const {
    loader: successLoader,
    message: successMessage,
    message_footer: successFooter,
    background_image: successBackground,
    image: successImage,
  } = success;
  const {
    message: resubscribeMessage,
    background_image: resubscribeBackground,
    image: resubscribeImage,
  } = resubscribe;

  const styles = [];

  createLabelStyles(resubscribeMessage, `.unsubscribe-resubscribe-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(successLoader, `.unsubscribe-success-loading`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(unsubscribeHeader, `.unsubscribe-landing-header-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(unsubscribeList, `.unsubscribe-landing-list`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(unsubscribeBody, `.unsubscribe-landing-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(unsubscribeFooter, `.unsubscribe-landing-footer`).forEach((style) => {
    styles.push(style);
  });
  createButtonStyles(unsubscribeButton, `.unsubscribe-landing-button`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(successMessage, `.unsubscribe-success-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(successFooter, `.unsubscribe-success-footer`).forEach((style) => {
    styles.push(style);
  });

  if (backgroundColor?.length > 0) {
    styles.push(
      `.unsubscribe-landing-container, .unsubscribe-success-container, .unsubscribe-resubscribe-container {background-color: ${backgroundColor};}`
    );
  }
  if (minWidth?.length > 0) {
    styles.push(
      `.unsubscribe-landing-inner-container, .unsubscribe-success-inner-container, .unsubscribe-resubscribe-inner-container {min-width: ${minWidth};}`
    );
  }

  if (maxWidth?.length > 0) {
    styles.push(
      `.unsubscribe-landing-inner-container, .unsubscribe-success-inner-container, .unsubscribe-resubscribe-inner-container {max-width: ${maxWidth};}`
    );
  }

  if (unsubscribeImage) {
    if (unsubscribeImage?.src?.length > 0) {
      styles.push(
        `.unsubscribe-landing-background-image { content: url(${unsubscribeImage?.src}); }`
      );
    }
    let rules = '';
    if (unsubscribeImage?.min_width?.length > 0) {
      rules += `min-width: ${unsubscribeImage?.min_width}; `;
    }
    if (unsubscribeImage?.max_width?.length > 0) {
      rules += `max-width: ${unsubscribeImage?.max_width};`;
    }
    if (unsubscribeImage?.height?.length > 0) {
      rules += `height: ${unsubscribeImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.unsubscribe-landing-background-image-container { ${rules} }`);
    }
  }

  if (successBackground) {
    if (successBackground?.src?.length > 0) {
      styles.push(
        `.unsubscribe-success-background-image { content: url(${successBackground?.src}); }`
      );
    }
    let rules = '';
    if (successBackground?.min_width?.length > 0) {
      rules += `min-width: ${successBackground?.min_width}; `;
    }
    if (successBackground?.max_width?.length > 0) {
      rules += `max-width: ${successBackground?.max_width};`;
    }
    if (successBackground?.height?.length > 0) {
      rules += `height: ${successBackground?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.unsubscribe-success-background-image-container { ${rules} }`);
    }
  }

  if (successImage) {
    if (successImage?.src?.length > 0) {
      styles.push(`.unsubscribe-success-image { content: url(${successImage?.src}); }`);
    }
    let rules = '';
    if (successImage?.width?.length > 0) {
      rules += `width: ${successImage?.width}; `;
    }
    if (successImage?.height?.length > 0) {
      rules += `height: ${successImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.unsubscribe-success-image-container { ${rules} }`);
    }
  }

  if (resubscribeBackground) {
    if (resubscribeBackground?.src?.length > 0) {
      styles.push(
        `.unsubscribe-resubscribe-background-image { content: url(${resubscribeBackground?.src}); }`
      );
    }
    let rules = '';
    if (resubscribeBackground?.min_width?.length > 0) {
      rules += `min-width: ${resubscribeBackground?.min_width}; `;
    }
    if (resubscribeBackground?.max_width?.length > 0) {
      rules += `max-width: ${resubscribeBackground?.max_width};`;
    }
    if (resubscribeBackground?.height?.length > 0) {
      rules += `height: ${resubscribeBackground?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.unsubscribe-resubscribe-background-image-container { ${rules} }`);
    }
  }

  if (resubscribeImage) {
    if (resubscribeImage?.src?.length > 0) {
      styles.push(`.unsubscribe-resubscribe-image { content: url(${resubscribeImage?.src}); }`);
    }
    let rules = '';
    if (resubscribeImage?.width?.length > 0) {
      rules += `width: ${resubscribeImage?.width}; `;
    }
    if (resubscribeImage?.height?.length > 0) {
      rules += `height: ${resubscribeImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.unsubscribe-resubscribe-image-container { ${rules} }`);
    }
  }
  return styles;
}
