import createLabelStyles from '../../components/label/style-loader';

export default function createStyles(config) {
  const {
    background_color: backgroundColor,
    background_image: backgroundImage,
    error,
    min_width: minWidth,
    max_width: maxWidth,
  } = config || {};
  const { background_color: errorContainerColor, label: errorLabel, code: errorCode } = error || {};

  const styles = [];

  createLabelStyles(errorLabel, `.error-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(errorCode, `.error-code`).forEach((style) => {
    styles.push(style);
  });

  if (backgroundColor?.length > 0) {
    styles.push(`.error-content {background-color: ${backgroundColor};}`);
  }

  if (minWidth?.length > 0) {
    styles.push(`.error-inner-container {min-width: ${minWidth};}`);
  }

  if (maxWidth?.length > 0) {
    styles.push(`.error-inner-container {max-width: ${maxWidth};}`);
  }

  if (errorContainerColor?.length > 0) {
    styles.push(`.error-message-container {background-color: ${errorContainerColor};}`);
  }

  if (backgroundImage) {
    if (backgroundImage?.src?.length > 0) {
      styles.push(`.error-background-image { content: url(${backgroundImage?.src}); }`);
    }
    let rules = '';
    if (backgroundImage?.min_width?.length > 0) {
      rules += `min-width: ${backgroundImage?.min_width}; `;
    }
    if (backgroundImage?.max_width?.length > 0) {
      rules += `max-width: ${backgroundImage?.max_width};`;
    }
    if (backgroundImage?.height?.length > 0) {
      rules += `height: ${backgroundImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.error-background-image-container { ${rules} }`);
    }
  }

  return styles;
}
