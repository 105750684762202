import createLabelStyles from '../../components/label/style-loader';
import createButtonStyles from '../../components/button/style-loader';
import createInputStyles from '../../components/input/style-loader';
import createFacebookStyles from '../../components/facebook/style-loader';
import createGoogleStyles from '../../components/google/style-loader';

export default function createStyles(config) {
  const {
    background_color: backgroundColor,
    landing,
    link,
    min_width: minWidth,
    max_width: maxWidth,
  } = config || {};
  const {
    background_image: landingImage,
    footer: landingFooter,
    button,
    input,
    label: landingLabel,
    facebook,
    google,
  } = landing || {};
  const {
    background_image: linkBackgroundImage,
    link_image: linkImage,
    label: linkLabel,
    footer: linkFooter,
  } = link;

  const styles = [];

  createFacebookStyles(facebook, `.login-landing-facebook`).forEach((style) => {
    styles.push(style);
  });
  createGoogleStyles(google, `.login-landing-google`).forEach((style) => {
    styles.push(style);
  });

  createLabelStyles(landingLabel, `.login-landing-welcome-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(landingFooter, `.login-landing-footer`).forEach((style) => {
    styles.push(style);
  });
  createButtonStyles(button, `.login-landing-button`).forEach((style) => {
    styles.push(style);
  });
  createInputStyles(input, `.login-landing-input`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(linkLabel, `.login-link-text`).forEach((style) => {
    styles.push(style);
  });
  createLabelStyles(linkFooter, `.login-link-footer`).forEach((style) => {
    styles.push(style);
  });

  if (backgroundColor?.length > 0) {
    styles.push(
      `.login-landing-container, .login-link-container {background-color: ${backgroundColor};}`
    );
  }
  if (minWidth?.length > 0) {
    styles.push(
      `.login-landing-inner-container, .login-link-inner-container {min-width: ${minWidth};}`
    );
  }

  if (maxWidth?.length > 0) {
    styles.push(
      `.login-landing-inner-container, .login-link-inner-container {max-width: ${maxWidth};}`
    );
  }

  if (landingImage) {
    if (landingImage?.src?.length > 0) {
      styles.push(`.login-landing-background-image { content: url(${landingImage?.src}); }`);
    }
    let rules = '';
    if (landingImage?.min_width?.length > 0) {
      rules += `min-width: ${landingImage?.min_width}; `;
    }
    if (landingImage?.max_width?.length > 0) {
      rules += `max-width: ${landingImage?.max_width};`;
    }
    if (landingImage?.height?.length > 0) {
      rules += `height: ${landingImage?.height};`;
    }

    if (rules.length > 0) {
      styles.push(`.login-landing-background-image-container { ${rules} }`);
    }
  }

  if (linkBackgroundImage) {
    if (linkBackgroundImage?.src?.length > 0) {
      styles.push(`.login-link-background-image { content: url(${linkBackgroundImage?.src}); }`);
    }
    let rules = '';
    if (linkBackgroundImage?.min_width?.length > 0) {
      rules += `min-width: ${linkBackgroundImage?.min_width}; `;
    }
    if (linkBackgroundImage?.max_width?.length > 0) {
      rules += `max-width: ${linkBackgroundImage?.max_width};`;
    }
    if (linkBackgroundImage?.height?.length > 0) {
      rules += `height: ${linkBackgroundImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.login-link-background-image-container { ${rules} }`);
    }
  }
  if (linkImage) {
    if (linkImage?.src?.length > 0) {
      styles.push(`.login-link-image { content: url(${linkImage?.src}); }`);
    }
    let rules = '';
    if (linkImage?.width?.length > 0) {
      rules += `width: ${linkImage?.width}; `;
    }
    if (linkImage?.height?.length > 0) {
      rules += `height: ${linkImage?.height};`;
    }
    if (rules.length > 0) {
      styles.push(`.login-link-image-container { ${rules} }`);
    }
  }

  return styles;
}
